import { createNativeStackNavigator } from '@react-navigation/native-stack'

import React from 'react'

import Home from '#screens/Home'
import { RootNativeStackParamList } from '#types'

const Stack = createNativeStackNavigator<RootNativeStackParamList>()

function WebNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, gestureEnabled: false, animation: 'none' }}>
      <Stack.Screen
        name="Home"
        options={{ title: 'Calculadora de Billetes by Daruma Cloud' }}
        component={Home}
      />
    </Stack.Navigator>
  )
}

export default WebNavigator
